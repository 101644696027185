import React, { useContext } from 'react';
import { ModalContext } from "../../Context/Modalcontext";
import { AnimatePresence, motion } from "framer-motion";
import { MdPayments } from "react-icons/md";

const Modal = () => {
    const { isModalOpen, setIsModalOpen } = useContext(ModalContext);

    if (!isModalOpen) return null;

    const redirectToLink = () => {
        window.open('https://www.webpay.cl/company/114743', '_blank')
    };

    return (
        <AnimatePresence>
            {isModalOpen && (
                <motion.div
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    exit={{ opacity: 0 }}
                    className="bg-slate-900/20 backdrop-blur p-8 fixed inset-0 z-50 grid place-items-center overflow-y-scroll cursor-pointer"
                    onClick={() => setIsModalOpen(false)}
                >
                    <motion.div
                        initial={{ scale: 0, rotate: "12.5deg" }}
                        animate={{ scale: 1, rotate: "0deg" }}
                        exit={{ scale: 0, rotate: "0deg" }}
                        onClick={(e) => e.stopPropagation()}
                        className="bg-gradient-to-br from-customBlue to-customGreen text-customLight p-6 rounded-lg w-full max-w-lg shadow-xl cursor-default relative overflow-hidden"
                    >
                        <button 
                            className="text-white bg-red-500 hover:bg-red-600 font-semibold text-sm py-1 px-2 rounded absolute top-3 right-3 cursor-pointer"
                            onClick={() => setIsModalOpen(false)}
                        >
                            Cerrar
                        </button>
                        <div className="relative z-10">
                            <div className="bg-green-500 w-16 h-16 mb-2 rounded-full text-3xl text-customBlue grid place-items-center mx-auto">
                                <MdPayments />
                            </div>
                            <h3 className="text-3xl font-bold text-center mb-2">
                                Link de pago disponible!
                            </h3>
                            <p className="text-center mb-6">
                                Ahora serás redirigido al portal de Link de pago de transbank, para completar el proceso de pago
                                por favor sigue todas las instrucciones:
                            </p>
                            <div className="flex gap-2">
                                {/* Botón modificado para redirigir a Webpay */}
                                <button
                                    onClick={redirectToLink}
                                    className="bg-customBright hover:opacity-90 transition-opacity text-customBlue font-semibold w-full py-2 rounded"
                                >
                                    Ir a link de pago 💸
                                </button>
                            </div>
                        </div>
                    </motion.div>
                </motion.div>
            )}
        </AnimatePresence>
    );
};


export default Modal;
