import React, { useState, useContext } from "react";
import { IoIosMail } from "react-icons/io";
import { IoMenu } from "react-icons/io5";
import { Link } from "react-scroll";
import { ModalContext } from "../../Context/Modalcontext";
import myLogo from "../../Assets/logo-issystem.png";
import "./navbar.css";

export const Navbar = () => {
    const [showMenu, setShowMenu] = useState(false);
    const { setIsModalOpen } = useContext(ModalContext);

    return (
        <nav className="fixed left-[50%] top-2 flex w-[calc(100%-4rem)] -translate-x-[50%] items-center gap-8 rounded-xl border-[1px] border-neutral-700 py-2 px-6 text-sm text-custom-color font-bold z-50 bg-opacity-30 bg-customLight backdrop-filter backdrop-blur-lg md:py-1 sm:py-0">
            <Link to="inicio" spy={true} smooth={true} offset={-70} duration={800}>
                <img src={myLogo} alt="mylogo" className="mylogo cursor-pointer"/>
            </Link>
            <div className="md:hidden">
                <IoMenu onClick={() => setShowMenu(!showMenu)} className="text-2xl cursor-pointer"/>
            </div>
            <div className={`${showMenu ? 'block' : 'hidden'} md:hidden bg-customLight  rounded-md cursor-pointer dropdown-menu`}>
                <ul className="space-y-2">
                    <li className="hover:bg-customGreen"><Link to="inicio" spy={true} smooth={true} offset={-70} duration={800} onClick={() => setShowMenu(false)}>Inicio</Link></li>
                    <li className="hover:bg-customGreen"><Link to="info" spy={true} smooth={true} offset={-70} duration={800} onClick={() => setShowMenu(false)}>Quiénes somos</Link></li>
                    <li className="hover:bg-customGreen"><Link to="clients" spy={true} smooth={true} offset={-70} duration={800} onClick={() => setShowMenu(false)}>Clientes</Link></li>
                    <li className="hover:bg-customGreen"><Link to="contact" spy={true} smooth={true} offset={-70} duration={800} onClick={() => setShowMenu(false)}>Contáctanos</Link></li>
                </ul>
            </div>
            <div className="hidden md:flex flex-grow items-center">
                <Link activeClass="active" to="inicio" spy={true} smooth={true} offset={-70} duration={800} className="cursor-pointer mr-6">Inicio</Link>
                <Link activeClass="active" to="info" spy={true} smooth={true} offset={-70} duration={800} className="cursor-pointer mr-6">Quiénes somos</Link>
                <Link activeClass="active" to="clients" spy={true} smooth={true} offset={-70} duration={800} className="cursor-pointer mr-6">Clientes</Link>
                <button onClick={() => setIsModalOpen(true)} className="bg-customBlue text-white font-medium py-2 px-4 rounded transition-all hover:text-customBlue hover:bg-customBright active:scale-95 cursor-pointer ml-40 mr-6">Link de pago</button>
                <Link to="contact" spy={true} smooth={true} offset={-70} duration={800} className="button-link">
                    <button className="nav-button bg-customBlue text-white font-medium py-2 px-4 flex items-center rounded transition-all hover:text-customBlue hover:bg-customBright active:scale-95 cursor-pointer">
                        <IoIosMail className="mr-2"/>Contáctanos!
                    </button>
                </Link>
            </div>
        </nav>
    );
};

export default Navbar;
