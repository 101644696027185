import React, { useState, useRef } from 'react';
import { AnimatePresence, motion } from 'framer-motion';
import emailjs from 'emailjs-com';

const BASE_TRANSITION = { ease: "anticipate", duration: 0.75 };

const Contact = () => {
    const [selected, setSelected] = useState("individual");

    return (
        <section id="contact" className="p-4 bg-slate-100">
            <div className="w-full max-w-6xl mx-auto shadow-lg flex flex-col-reverse lg:flex-row rounded-lg overflow-hidden">
                <Form selected={selected} setSelected={setSelected} />
                <Images selected={selected} />
            </div>
        </section>
    );
};

const Form = ({ selected, setSelected }) => {
    const form = useRef();
    const formClasses = selected === "company" ? "bg-customBlue text-customGreen" : "bg-customAqua text-customBlue";
    const inputClasses = selected === "company" ? "bg-customLight placeholder-customGreen" : "bg-customLight placeholder-customBlue";

    const validateEmail = (email) => {
        const re = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
        return re.test(String(email).toLowerCase());
    }

    const sendEmail = (e) => {
        e.preventDefault();
        const email = form.current.elements.from_email.value;

        if (!validateEmail(email)) {
            alert('Por favor, ingresa un correo electrónico válido.');
            return;
        }

        emailjs.sendForm(
            'service_0ast9le',
            'template_rhyde4o',
            form.current,
            'YRYuaperHQwFanGoG'
        ).then((result) => {
            console.log(result.text);
            alert('Email enviado con éxito');
            e.target.reset();
        }, (error) => {
            console.log(error.text);
            alert('Hubo un error al enviar el correo, inténtalo de nuevo.');
        });
    };

    return (
        <form onSubmit={sendEmail} ref={form} className={`p-8 w-full transition-colors duration-[750ms] ${formClasses}`}>
            <h3 className="text-4xl font-bold mb-6">Contáctanos</h3>

            <div className="mb-6">
                <p className="text-2xl mb-2">Nombre Completo:</p>
                <input
                    name="from_name"
                    type="text"
                    placeholder="Tu nombre completo..."
                    className={`p-2 rounded-md w-full focus:outline-0 ${inputClasses}`}
                />
            </div>

            <div className="mb-6">
                <p className="text-2xl mb-2">Correo Electrónico:</p>
                <input
                    name="from_email"
                    type="email"
                    placeholder="Tu correo electrónico..."
                    className={`p-2 rounded-md w-full focus:outline-0 ${inputClasses}`}
                />
            </div>

            <div className="mb-6">
                <p className="text-2xl mb-2">y escribo en representación...</p>
                <FormSelect selected={selected} setSelected={setSelected} />
            </div>

            <AnimatePresence>
                {selected === "company" && (
                    <motion.div
                        initial={{ marginTop: -104, opacity: 0 }}
                        animate={{ marginTop: 0, opacity: 1 }}
                        exit={{ marginTop: -104, opacity: 0 }}
                        transition={BASE_TRANSITION}
                        className="mb-6"
                    >
                        <p className="text-2xl mb-2">Nombre de Empresa:</p>
                        <input
                            name="company_name"
                            type="text"
                            placeholder="Nombre de tu empresa..."
                            className={`p-2 rounded-md w-full focus:outline-0 ${inputClasses}`}
                        />
                    </motion.div>
                )}
            </AnimatePresence>

            <div className="mb-6">
                <p className="text-2xl mb-2">Consulta:</p>
                <textarea
                    name="message"
                    placeholder="Tu consulta..."
                    className={`min-h-[150px] resize-none p-2 rounded-md w-full focus:outline-0 ${inputClasses}`}
                />
            </div>

            <motion.button
                whileHover={{ scale: 1.01 }}
                whileTap={{ scale: 0.99 }}
                type="submit"
                className={`text-lg text-center rounded-lg w-full py-3 font-semibold transition-colors duration-[750ms] ${
                    selected === "company" ? "bg-white text-customBlue" : "bg-white text-customAqua"
                }`}
            >
                Enviar
            </motion.button>
        </form>
    );
};

const FormSelect = ({ selected, setSelected }) => {
    return (
        <div className="border-[1px] rounded border-white overflow-hidden font-medium w-fit">
            <button
                className={`${
                selected === "individual" ? "text-customBlue" : "text-customAqua"
                } text-sm px-3 py-1.5 transition-colors duration-[750ms] relative`}
                onClick={() => setSelected("individual")}
            >
                <span className="relative z-10">Particular</span>
                {selected === "individual" && (
                    <motion.div
                        transition={BASE_TRANSITION}
                        layoutId="form-tab"
                        className="absolute inset-0 bg-white z-0"
                    />
                )}
            </button>
            <button
                className={`${
                selected === "company" ? "text-customBlue" : "text-customBlue"
                } text-sm px-3 py-1.5 transition-colors duration-[750ms] relative`}
                onClick={() => setSelected("company")}
            >
                <span className="relative z-10">De una Empresa</span>
                {selected === "company" && (
                    <motion.div
                        transition={BASE_TRANSITION}
                        layoutId="form-tab"
                        className="absolute inset-0 bg-white z-0"
                    />
                )}
            </button>
        </div>
    );
};


const Images = ({ selected }) => {
    return (
    <div className="bg-white relative overflow-hidden w-full min-h-[100px]">
        <motion.div
            initial={false}
            animate={{
            x: selected === "individual" ? "0%" : "100%",
            }}
            transition={BASE_TRANSITION}
            className="absolute inset-0 bg-slate-200"
            style={{
            backgroundImage:
                "url(https://images.unsplash.com/photo-1499951360447-b19be8fe80f5?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1740&q=80)",
            backgroundSize: "cover",
            backgroundPosition: "center",
            }}
            />
        <motion.div
        initial={false}
        animate={{
        x: selected === "company" ? "0%" : "-100%",
        }}
        transition={BASE_TRANSITION}
        className="absolute inset-0 bg-slate-200"
        style={{
        backgroundImage:
            "url(https://images.unsplash.com/photo-1556761175-5973dc0f32e7?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1932&q=80)",
        backgroundSize: "cover",
        backgroundPosition: "center",
        }}
        />
    </div>
    );
};

export default Contact;

