import React from 'react';
import { RiWhatsappFill } from "react-icons/ri";



const Whatsapp = () => {
const whatsappURL = "https://wa.me/56935133199";

    return (
        <a href={whatsappURL} target="_blank" rel="noopener noreferrer" className="fixed bottom-4 right-4 z-50 p-3 rounded-full bg-green-500 text-white shadow-lg hover:bg-green-600">
            <RiWhatsappFill size={55} />
        </a>
    );
};

export default Whatsapp;
