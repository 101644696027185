import React, { useEffect, useRef, useState, useContext } from 'react';
import "./home.css";
import { motion } from "framer-motion";
import { ModalContext } from "../../Context/Modalcontext";
import { Link } from "react-scroll";
import { IoIosMail } from "react-icons/io";
import Est1 from "../../Assets/parking1.jpg";
import Est2 from "../../Assets/parking2.jpg";
import Est3 from "../../Assets/parking3.jpg";
import Est4 from "../../Assets/parking4.jpg";
import Est6 from "../../Assets/parking6.jpg";
import Est8 from "../../Assets/parking8.jpg";
import Est9 from "../../Assets/parking9.jpg";
import Est11 from "../../Assets/parking11.jpg";
import Est12 from "../../Assets/parking13.jpg";



const Home = () => {
    const { setIsModalOpen } = useContext(ModalContext);

    return (
        <section id="inicio" className="w-full h-[95vh] px-8 py-12 grid grid-cols-1 md:grid-cols-2 items-center gap-8 max-w-6xl mx-auto bg-customLight z-10 sm:h-[calc(95vh+50px)]">
            <div className="flex flex-col justify-center h-full">
                <h1 className="text-6xl font-black leading-[1.1] md:text-8xl text-customBlue mb-4 sm:mt-30 home-title">
                    IS <span className="text-customGris">SYSTEM</span>
                </h1>
                <p className="max-w-xl text-xl font-bold text-customBlue mb-4 home-subtitle">
                    El futuro en gestión de ESTACIONAMIENTOS 🅿️
                </p>
                <button onClick={() => setIsModalOpen(true)}
                className="bg-customBlue text-customLight font-medium py-2 px-4 rounded transition-all hover:bg-customBright hover:text-customBlue active:scale-95 mb-4 cursor-pointer">
                    Link de pago
                </button>
                <Link to="contact" spy={true} smooth={true} offset={-70} duration={500}>
                    <button className="bg-customBlue text-customLight font-medium py-2 px-6 flex items-center justify-center gap-2 rounded transition-all hover:bg-customBright hover:text-customBlue active:scale-95 cursor-pointer">
                        <IoIosMail className="mr-2"/>
                        Contáctanos
                    </button>
                </Link>
            </div>
            <ShuffleGrid />
        </section>
    );
};

const shuffle = (array) => {
    let currentIndex = array.length, randomIndex;
    while (currentIndex !== 0) {
        randomIndex = Math.floor(Math.random() * currentIndex);
        currentIndex--;
        [array[currentIndex], array[randomIndex]] = [array[randomIndex], array[currentIndex]];
    }
    return array;
};

const squareData = [
    { id: 1, src: Est1 },
    { id: 2, src: Est2 },
    { id: 3, src: Est3 },
    { id: 4, src: Est4 },
    { id: 6, src: Est6 },
    { id: 8, src: Est8 },
    { id: 9, src: Est9 },
    {id: 11, src: Est11 },
    { id: 12, src: Est12 },
];

const generateSquares = () => {
    return shuffle(squareData).map((sq) => (
        <motion.img
            key={sq.id}
            src={sq.src}
            alt={`Estacionamiento ${sq.id}`}
            transition={{ duration: 7, type: "spring" }}
            className="w-full h-full object-cover"
         //   loading="lazy"
        />
    ));
};

const ShuffleGrid = () => {
    const timeoutRef = useRef(null);
    const [squares, setSquares] = useState(generateSquares());

    useEffect(() => {
        const shuffleSquares = () => {
            setSquares(generateSquares());
            timeoutRef.current = setTimeout(shuffleSquares, 7000);
        };

        shuffleSquares();

        return () => clearTimeout(timeoutRef.current);
    }, []);

    return (
        <div className="grid grid-cols-3 grid-rows-3 h-[450px] gap-1 shuffle-grid ">
            {squares}
        </div>
    );
};

export default Home;


