import React from 'react';
import { ModalProvider } from './Context/Modalcontext';
import Navbar from './Components/Navbar/navbar';
import Home from './Components/Home/home';
import Info from './Components/Info/info';
import Clients from './Components/Clients/clients';
import Contact from './Components/Contact/contact';
import Whatsapp from './Components/Whatsapp/whatsapp';
import Footer from './Components/Footer/footer';
import Modal from './Components/Modal/modalcomponent';


function App() {
  return (
    <ModalProvider>
      <div className="App">
        <Navbar />
        <Modal />
        <Home />
        <Info />
        <Clients />
        <Contact />
        <Footer />
        <Whatsapp />
      </div>
    </ModalProvider>
  );
}

export default App;
