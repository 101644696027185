import React from "react";
import { motion } from "framer-motion";
import { FiBatteryCharging, FiWifi } from "react-icons/fi";
import "./info.css";
import myImage from "../../Assets/main-image.PNG";
import { FcAssistant, FcReading, FcPositiveDynamic, FcRules, FcOk, FcMoneyTransfer } from "react-icons/fc";

const Info = () => {
    return (
        <section id="info" className="py-12 bg-customLight">
            <div className="container mx-auto">
                <div className="text-center mb-12 info-title">
                    <h2 className="text-4xl font-bold text-customBlue mb-5">¿Qué es IS SYSTEM?</h2>
                    <p className="max-w-[80vw] text-lg text-customGris font-semibold info-subtitle">
                        Somos una empresa Chilena con más de 6 años de experiencia en logística y gestión de estacionamientos.
                    </p>
                </div>

                <div className="flex flex-wrap justify-center info-container">
                    <div className="w-full lg:w-1/3 px-4 mb-9">
                        <InfoCard title="6 años operando" description="Sin incidentes y con experiencias satisfactorias para nuestros clientes" icon={<FcAssistant />} />
                        <InfoCard title="19.000 autos" description="Estacionados por día desde que iniciamos nuestras operaciones!" icon={<FcPositiveDynamic />} />
                        <InfoCard title="5 contratos" description="Avalan nuestra experiencia en gestión de estacionamientos" icon={<FcRules />} />
                    </div>

                    <div className="w-full lg:w-1/3 px-4 mb-9">
                        <InfoCard title="Experiencia en licitaciones" description="Nuestros estándares de trabajo se ajustan a los estándares de entidades públicas" icon={<FcReading />} />
                        <InfoCard title="100% de satisfacción" description="Todos nuestros clientes vuelven a confiar en nuestro trabajo" icon={<FcOk />} />
                        <InfoCard title="Pago a un click!" description="Ahora todos los usuarios de estacionamientos IS SYSTEM podrán pagar su servicio a través de nuestro link de pago!" icon={<FcMoneyTransfer />} />
                    </div>

                    <div className="w-full lg:w-1/3 px-4 mb-9 flex justify-center floating-phone-container">
                        <FloatingPhone />
                    </div>
                </div>
            </div>
        </section>
    );
};

const InfoCard = ({ icon, title, description }) => {
    return (
        <div className="info-card rounded-lg bg-customGreen p-4 shadow hover:shadow-xl mb-6 w-60 h-30 flex flex-col justify-between">
            <div className="flex items-center mb-2">
                {icon && <div className="mr-2 text-xl">{icon}</div>}
                <h4 className="text-md font-semibold text-customBlue">{title}</h4>
            </div>
            <p className="text-xs text-customBlue">{description}</p>
        </div>
    );
};

// En tu componente FloatingPhone
const FloatingPhone = () => {
    return (
        <div
            className="floating-phone relative mx-auto w-60 h-96 rounded-[24px] bg-customBlue shadow-xl overflow-hidden transform-gpu"
            style={{ transform: "rotateY(-30deg) rotateX(15deg)" }}
        >
            <motion.div
                initial={{ y: -2, scale: 0.98 }}
                animate={{ y: -4, scale: 1 }}
                transition={{ repeat: Infinity, repeatType: "mirror", duration: 2, ease: "easeInOut" }}
                className="relative w-full h-full rounded-[24px] border-2 border-b-4 border-r-4 border-white border-l-neutral-200 border-t-neutral-200 bg-neutral-900 p-1 pl-[3px] pt-[3px]"
            >
                <HeaderBar />
                <Screen />
            </motion.div>
            <div className="absolute -bottom-5 left-1/2 transform -translate-x-1/2 w-3/4 h-3 rounded-full bg-gray-600 opacity-40"></div>
        </div>
    );
};


const HeaderBar = () => {
    return (
        <>
            <div className="absolute left-[50%] top-2.5 z-10 h-2 w-16 -translate-x-[50%] rounded-md bg-neutral-900"></div>
            <div className="absolute right-3 top-2 z-10 flex gap-2">
                <FiWifi className="text-neutral-600" />
                <FiBatteryCharging className="text-neutral-600" />
            </div>
        </>
    );
};

const Screen = () => {
    return (
        <div className="relative z-0 grid h-full w-full place-content-center overflow-hidden rounded-[20px] bg-customGris">
            <img src={myImage} alt="myImage" />
            <button className="absolute bottom-4 left-4 right-4 z-10 rounded-lg border-[1px] bg-white py-2 text-sm font-medium text-customBlue backdrop-blur">
                Link de pago
            </button>
        </div>
    );
};

export default Info;



