import { useState } from "react";
import { motion } from "framer-motion";
import LogoSanber from "../../Assets/logo-sanbernardo.png";
import LogoFidae from "../../Assets/logo-fidae.png";
import LogoPi from "../../Assets/logo-pichilemu.png";
import LogoPu from "../../Assets/logo-puchuncavi.png";
import LogoVa from "../../Assets/logo-vallenar.png";
import LogoPucon from "../../Assets/muni-pucon.png";


const Clients = () => {
const [selected, setSelected] = useState(0);

    return (
    <section id="clients"className="bg-customBlue py-20 px-4 lg:px-8 grid items-center grid-cols-1 lg:grid-cols-2 gap-8 lg:gap-4 overflow-hidden">
        <div className="p-4">
            <h3 className="text-5xl font-semibold text-customLight"> Nuestros Clientes</h3>
            <p className="text-customGreen my-4">
            Nuestra experiencia en la gestión de estacionamientos y parquímetros ha sido destacada por las
            siguientes entidades:
            </p>
            <SelectBtns
            numTracks={testimonials.length}
            setSelected={setSelected}
            selected={selected}
            />
        </div>
        <Cards
        testimonials={testimonials}
        setSelected={setSelected}
        selected={selected}
        />
    </section>
    );
};

const SelectBtns = ({ numTracks, setSelected, selected }) => {
    return (
    <div className="flex gap-1 mt-8">
        {Array.from(Array(numTracks).keys()).map((n) => {
            return (
            <button
                key={n}
                onClick={() => setSelected(n)}
                className="h-1.5 w-full bg-customBright relative"
                >
                {selected === n ? (
                <motion.span
                    className="absolute top-0 left-0 bottom-0 bg-slate-950"
                    initial={{
                    width: "0%",
                    }}
                    animate={{
                    width: "100%",
                    }}
                    transition={{
                    duration: 13,
                    }}
                    onAnimationComplete={() => {
                    setSelected(selected === numTracks - 1 ? 0 : selected + 1);
                    }}
                />
                ) : (
                <span
                    className="absolute top-0 left-0 bottom-0 bg-slate-950"
                    style={{
                    width: selected > n ? "100%" : "0%",
                    }}
                />
                )}
            </button>
            );
        })}
    </div>
    );
};

const Cards = ({ testimonials, selected, setSelected }) => {
    return (
    <div className="p-4 relative h-[450px] lg:h-[500px] shadow-xl">
        {testimonials.map((t, i) => {
            return (
            <Card
                {...t}
                key={i}
                position={i}
                selected={selected}
                setSelected={setSelected}
            />
            );
        })}
    </div>
    );
};

const Card = ({
    Icon,
    name,
    title,
    position,
    selected,
    setSelected,
}) => {
    const scale = position <= selected ? 1 : 1 + 0.015 * (position - selected);
    const offset = position <= selected ? 0 : 95 + (position - selected) * 3;

    return (
        <motion.div
            initial={false}
            style={{
                zIndex: position,
                transformOrigin: "center bottom", // Cambiado de "left bottom" a "center bottom"
                background: "white",
                color: "black",
            }}
            animate={{
                x: `${offset}%`,
                scale,
            }}
            whileHover={{
                translateX: position === selected ? 0 : -3,
            }}
            transition={{
                duration: 1,
                ease: "easeOut",
            }}
            onClick={() => setSelected(position)}
            className="absolute top-0 left-0 w-full min-h-full p-8 lg:p-12 cursor-pointer flex flex-col justify-center items-center rounded-sm" // Se añade justify-center y items-center para centrar el contenido
        >
            <Icon className="text-7xl mx-auto mb-4" />
            <div className="text-center"> {/* Se agrega text-center para centrar el nombre y título */}
                <span className="block font-semibold text-lg">{name}</span>
                <span className="block text-sm">{title}</span>
            </div>
        </motion.div>
    );
};


const testimonials = [
    {
        Icon: () => <img src={LogoSanber} alt="Logo San Bernardo" style={{width: '240px', height: '240px' }} />,
        description: "",
        name: "Municipalidad de San Bernardo",
        title: "Reciente Contrato año 2024"
    },
    {
        Icon: () => <img src={LogoFidae} alt="Logo Fidae" style={{width: '240px', height: '240px' }} />,
        description: "",
        name: "Feria Internacional del Aire y Del Espacio",
        title: "Reciente Contrato año 2024, con más de 15.000 estacionamientos"
    },
    {
        Icon: () => <img src={LogoPucon} alt="Logo Pucón" style={{width: '240px', height: '240px' }} />,
        description: "",
        name: "Municipalidad de Pucón",
        title: "Contrato año 2024"
    },
    {
        Icon: () => <img src={LogoPi} alt="Logo Pichilemu" style={{width: '240px', height: '240px' }} />,
        description: "",
        name: "Municipalidad de Pichilemu",
        title: "Contrato año 2020 & Nuevo Contrato año 2023"
    },
    {
        Icon: () => <img src={LogoPu} alt="Logo Puchuncaví" style={{width: '240px', height: '240px' }}/>,
        description: "",
        name: "Municipalidad de Puchuncaví",
        title: "Contrato año 2021"
    },
    {
        Icon: () => <img src={LogoVa} alt="Logo Vallenar" style={{width: '240px', height: '240px' }} />,
        description: "",
        name: "Municipalidad de Vallenar",
        title: "Contrato año 2022 & Nuevo Contrato año 2023"
    }
];


export default Clients;